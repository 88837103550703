const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://xxemjc75kd.execute-api.us-west-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://7ea5cvkwwg.execute-api.us-west-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.2',
    HOSTNAME: 'https://teams-service.staging.wecomplai.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.wecomplai.forwoodsafety.com',
    WEBSOCKET: 'wss://ko46w5f5id.execute-api.us-west-2.amazonaws.com/staging'
  },
};

export default config;
